.ocean-container {
  background-color: hotpink;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ocean {
  position: absolute;
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
