.App {
  text-align: center;
}

.heading {
  background: #4a638a;
  color: #fff;
  margin: 0 7.5vw;
  padding: 0.75em 1em 1em;
  border-radius: 0 0 42px 42px;
  font-size: clamp(20px, 4.2vw, 42px);
}

main {
  margin: 2.5vh 7.5vw max(5vh, 100px);
  border-radius: 42px;
  font-size: clamp(20px, 3.33vw, 32px);
  line-height: 1.5;
  padding: 1em;
  background: #fff;
  color: #4a638a;
}

blockquote,
figcaption {
  all: unset;
  display: block;
}

main p {
  font-size: clamp(20px, 3.33vw, 32px);
}

main p.short {
  font-size: clamp(20px, 5vw, 32px);
  line-height: 1.5;
}
main p.long {
  font-size: clamp(20px, 2.5vw, 32px);
  line-height: 1.5;
}
main .source {
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  padding: 0.25em 0;
  box-sizing: border-box;
  line-height: 1;
  border-radius: 0;
  background: rgba(74, 99, 138, 0.5);
  z-index: 0;
  padding: 0.5em 7.5vw;
}

footer p {
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
}

footer .pause {
  z-index: 2;
  cursor: pointer;
}

@media screen and (min-width: 900px) {
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .heading {
    margin: 2.5vh 2.5vw;
    border-radius: 42px;
    padding: 32px;
  }
  main {
    margin: 2.5vh;
    padding: 32px;
  }
}
